import React from "react";
import Navbar from "./components/Navbar";
import "./global.css";

import HomePage from "./pages/Home";

import logo from "./images/logo.avif";
import AboutPage from "./pages/About";
import ServicesPage from "./pages/Services";
import ContactPage from "./pages/Contact";
import { Routes, Route } from "react-router-dom";

const navbarLinks: string[] = ["home", "o-nama", "usluge", "kontakt"];
const pageIds: string[] = navbarLinks.map(x => x.replace(/ /g, "%20"));
const navbarLogoAlt: string = "Logo";

const App: React.FC = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/.well-known/acme-challenge/Chj-nMhDfvdKiua5r4peaV7riN4-QYLKD0suwUgMj9yaHRrsWj7762wHV4Ch8hmE" />
        <Route path="*" element={
          <>
            <Navbar altText={navbarLogoAlt} links={navbarLinks} logoUrl={logo} />
            <HomePage id={pageIds[0]} />
            <AboutPage id={pageIds[1]} />
            <ServicesPage id={pageIds[2]} />
            <ContactPage id={pageIds[3]} />
          </>
        } />
      </Routes>
    </React.Fragment>
  );
}

export default App;

import React, { useEffect, useRef, useState } from "react";
import "./index.css";

import Hero from "../../components/Hero";
import Banner from "../../components/Banner";
import isometric from "../../images/isometric-construction-site.avif";

interface ServicesPageProps extends React.ComponentProps<"div"> {
}

export interface data {
  heightPx: number
}

const titles: Array<string> = [
  "Od malih projekata do velikih infrastrukturnih pothvata",
  "Širok raspon usluga niskogradnje"
];

const ServicesPage: React.FC<ServicesPageProps> = (props) => {
  const { ...rest } = props;
  const [width, setWidth] = useState<number>(window.innerWidth);
  const heroRef = useRef<HTMLDivElement | null>(null);
  const callbackHandler = (data: data) => {
    if (heroRef.current) {
      heroRef.current.style.paddingTop = String(data.heightPx) + "px";
    }
  }
  const handleResize = () => {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <Hero {...rest} ref={heroRef}>
      <Banner
        title="Usluge"
        subtitle="temelji našeg uspjeha"
        dataCallback={data => callbackHandler(data)}
      />
      <div className="services__grid__container">
        <div className="services__grid">
          <div className="column" id="column-1">
            <ServiceCard id="el-1" title="Iskopi i asfaltiranje">
              Specijalizirani smo za sve vrste iskopa - malih i velikih. Nudimo usluge pripreme terena za asfaltiranje, kao i asfaltiranje, osiguravajući dugotrajnost i kvalitetu.
            </ServiceCard>
            <ServiceCard id="el-2" title="Uređenje dvorišta">
              Naša usluga uređenja dvorišta obuhvaća izgradnju okućnica, postavljanje kocka, kao i instalaciju kanalizacijskih i vodovodnih cijevi za kompletan estetski i funkcionalan prostor.
            </ServiceCard>
            <h1>{width <= 1060 ? titles[1] : titles[0]}</h1>
          </div>
          <div className="column" id="column-2">
            <h1>{width <= 1060 ? titles[0] : titles[1]}</h1>
            <ServiceCard id="el-1" title="Fleksibilna rješenja uz poslovne partnere">
              Obrt ima dugogodišnje iskustvo u radu s poslovnim partnerima, od malih poduzetnika do velikih građevinskih tvrtki. Uvijek nudimo fleksibilna i inovativna rješenja.
            </ServiceCard>
            <ServiceCard id="el-2" title="Transport materijala">
              Osiguravamo pouzdanu uslugu transporta materijala, bez obzira na veličinu vašeg projekta. Brinemo se o brzom i sigurnom transportu materijala na gradilište te pazimo na održavanje čitoće gradilišta.
            </ServiceCard>
          </div>
        </div>
      </div>
      <div className="isometric__container">
        <img className="isometric__3d" src={isometric} alt="Isometric3D" />
      </div>
      <h1 className="services__vertical__text">NISKOGRADNJA</h1>
    </Hero>
  );
}

interface ServiceCardProps extends React.ComponentProps<"div"> {
  title: string,
  children?: React.ReactNode
}

const ServiceCard: React.FC<ServiceCardProps> = (props) => {
  const { title, children, ...rest } = props;
  return (
    <div className="service__card" {...rest}>
      <h1>{title}</h1>
      { children && <p>{children}</p>}
    </div>
  );
}

export default ServicesPage;

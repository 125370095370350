function scrollIntoView(elementId: string): void {
  elementId = elementId.replace(/ /g, "%20");
  const element = document.getElementById(elementId);
  if (elementId === "home" && element) {
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  } else if (element) {
    scrollIntoViewTargetAdjusted(elementId)
  } else {
    console.log("Element with id " + elementId + " does not exist.");
  }
};

// when scrolling to element that is not 'home', offset is needed
function scrollIntoViewTargetAdjusted(elementId: string): void {
  const element: HTMLElement | null = document.getElementById(elementId);
  if (element) {
    let navbarHeight: string = getComputedStyle(document.documentElement).getPropertyValue("--nav-height").trim();
    let elementPosition = element.getBoundingClientRect().top;
    let offsetPos = elementPosition + window.scrollY - (parseInt(navbarHeight) * 16);
    window.scrollTo({ top: offsetPos, behavior: "smooth" });
  }
}


export function navbarNavigation(event: any, url: string, callback?: () => void): void {
  event.preventDefault();
  window.location.hash = url;
  scrollIntoView(url);
  if (callback) {
    callback();
  }
}

import React from "react";
import "./index.css";

interface SecondaryButtonProps extends React.ComponentProps<"button"> {
  text: string
  callback?: () => void
}

const SecondaryButton: React.FC<SecondaryButtonProps> = (props) => {
  const { text, callback, ...rest } = props;
  return (
    <button className="secondary__button__component" { ...rest } onClick={callback}>
      {text}
    </button>
  );
}

export { SecondaryButton };

import React, { forwardRef } from "react";
import "./index.css";

interface HeroProps extends React.ComponentProps<"div"> {
  children?: React.ReactNode
}

const Hero = forwardRef<HTMLDivElement, HeroProps>((props, ref) => {
  const { children, ...rest } = props;
  return(
    <div className="hero__container" { ...rest } ref={ref}>
      <div className="hero__background__image__container"></div>
      { children }
    </div>
  );
});

export default Hero;

/* eslint-disable no-unused-expressions */
import React, { useEffect, useRef, useState } from "react";
import { sleep } from "../../utils/timeUtils";
import { navbarNavigation } from "../../utils/navigatonUtils";
import "./index.css";

interface NavbarProps {
  // imageRef.current as HTMLImageElement
  logoUrl?: string,
  altText?: string,
  links: string[]
};

const Navbar: React.FC<NavbarProps> = (props) => {
  const { logoUrl, altText, links } = props;
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
  const [animationActive, setAnimationActive] = useState<boolean>(false);
  const navRef = useRef<HTMLElement | null>(null);
  const hamIconRef = useRef<HTMLDivElement | null>(null);
  const dropMenuRef = useRef<HTMLUListElement | null>(null);
  const linksRef = useRef<Array<HTMLAnchorElement | null>>(
    [...new Array(links.length)].map(() => null)
  );

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
    // ako je drop__menu aktivan makni ga sa elementa
    if (window.innerWidth > 1060 && dropMenuRef.current) {
      if (dropMenuRef.current.classList.contains("drop__menu")) {
        dropMenuRef.current.classList.remove("drop__menu");
        document.body.classList.remove("disable__scroll");
      }
    }
  };

  const handleScroll = () => {
    if (navRef.current) {
      // e.x. 2rem
      let navMarginTop: string = getComputedStyle(document.documentElement).getPropertyValue("--nav-margin-top").trim();
      if (window.scrollY > (parseInt(navMarginTop) * 16))
        navRef.current.classList.add("nav__sticky");
      else
        navRef.current.classList.remove("nav__sticky");
    }
  }

  const toggleDropMenu = async () => {
    if (dropMenuRef.current && linksRef.current) {
      if (dropMenuRef.current.classList.contains("drop__menu"))
      {
        dropMenuRef.current.classList.remove("animate__drop__menu__forward");
        dropMenuRef.current.offsetWidth; /* eslint-disable-line */
        dropMenuRef.current.classList.add("animate__drop__menu__reverse");
        linksRef.current.forEach(el => {
          if (el) {
            el.classList.remove("animate__drop__links__forward");
            el.offsetWidth; /* eslint-disable-line */
            el.classList.add("animate__drop__links__reverse");
          }
        })
        setAnimationActive(true);
        await sleep(200).then(() => {
          if (dropMenuRef.current){
            dropMenuRef.current.classList.remove("drop__menu");
            document.body.classList.remove("disable__scroll");
          }
        });
        setAnimationActive(false);
      }
      else {
        dropMenuRef.current.classList.add("drop__menu");
        document.body.classList.add("disable__scroll");
        dropMenuRef.current.classList.remove("animate__drop__menu__reverse");
        dropMenuRef.current.classList.add("animate__drop__menu__forward");
        linksRef.current.forEach(el => {
          if (el) {
            el.classList.remove("animate__drop__links__reverse");
            el.classList.add("animate__drop__links__forward");
          }
        })
      }
    }
  }

  const handleHamIconClick = () => {
    if (hamIconRef.current && !animationActive) {
      const hamBars: HTMLDivElement[] = Array.from(hamIconRef.current.children) as HTMLDivElement[];
      hamBars.forEach(child => {
        child.classList.toggle("animate__hamburger");
      })
      toggleDropMenu();
    }
  }

  // resize event listener
  useEffect(() => {
    // set initial screen width
    handleResize();
    window.addEventListener("resize", handleResize);
    // on dismount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // scroll event listener
  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  return (
    <nav ref={navRef}>
      <span className="nav__content__container">
        <div className="nav__logo__container">
          <img onClick={e => navbarNavigation(e, "home")} src={logoUrl} alt={altText} />
        </div>
        {screenWidth <= 1060 &&
          <div className="nav__hamburger" ref={hamIconRef} onClick={handleHamIconClick}>
            <div id="bar-1"></div>
            <div id="bar-2"></div>
            <div id="bar-3"></div>
          </div>
        }
        <ul className="nav__links__container" ref={dropMenuRef}>
          {links.map((link, index) => (
            <li key={index}>
              <a
                onClick={e => navbarNavigation(e, link, handleHamIconClick)} 
                ref={el => linksRef.current[index] = el}
              >
                {link.replace(/-/g, " ")}
              </a>
            </li>
          ))}
        </ul>
      
      </span>
    </nav>
  );
}

export default Navbar;

import React, { useEffect, useRef, useState } from "react";
import "./index.css";

import Hero from "../../components/Hero";
import Banner from "../../components/Banner";
import { data } from "../Services";
import highlighter from "../../images/highlighter.avif";
import phoneIcon from "../../images/phone-icon.avif";
import clockIcon from "../../images/clock-icon.avif";

interface ContactPageProps extends React.ComponentProps<"div"> {
}

const ContactPage: React.FC<ContactPageProps> = (props) => {
  const { ...rest } = props;
  const heroRef = useRef<HTMLDivElement | null>(null);
  const callbackHandler = (data: data) => {
    if (heroRef.current) {
      heroRef.current.style.paddingTop = String(data.heightPx) + "px";
    }
  }
  return (
    <Hero {...rest} ref={heroRef}>
      <Banner
        title="Kontakt"
        subtitle="odaberite nas za ostvarenje vaših građevinskih projekata"
        dataCallback={data => callbackHandler(data)}
      />
      <h1 className="call__to__action">
        Započnite svoje projekte <span id="s__nama">s nama.<img src={highlighter} alt="highlighter"/></span>
      </h1>
      <div className="contact__content">
        <div className="mobitel__radno__vrijeme">
          <Mobitel />
          <RadnoVrijeme />
        </div>
        <div className="lokacija">
          <Lokacija />
        </div>
      </div>
    </Hero>
  );
}

const Mobitel: React.FC = () => {
  return (
    <div className="mobitel__component">
      <h1>Mobitel:</h1>
      <p>
        <a href="tel:+38598236858">+385 98 236 858</a>
      </p>
      <div className="phone__icon__container">
        <img src={phoneIcon} alt="phoneIcon" />
      </div>
    </div>
  );
}

const RadnoVrijeme: React.FC = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const radnoVrijemeRef = useRef<HTMLDivElement | null>(null);
  const radnoVrijemeBeforeRef = useRef<HTMLDivElement | null>(null);
  const resizeHandler = (): void => {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    if (radnoVrijemeBeforeRef.current && radnoVrijemeRef.current) {
      radnoVrijemeBeforeRef.current.style.left = "-" + radnoVrijemeRef.current.offsetLeft + "px";      
    }
  }, [width]);
  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener("resize", resizeHandler);
    return () => window.removeEventListener("resize", resizeHandler);
  }, []);
  return (
    <div className="radno__vrijeme__component" ref={radnoVrijemeRef}>
      <div className="radno__vrijeme__component__before" ref={radnoVrijemeBeforeRef}>
        <div className="rv__before__dots">
          {[...Array(5)].map((_, index) => {
            return <div key={"rv__dot__" + index} id={"rv__dot__" + index} />;
          })}
        </div>
      </div>
      <h1>Radno vrijeme:</h1>
      <div className="info">
        <p id="dani-1">Pon-Pet</p><b id="vrijeme-1">7:30 - 16:00</b>
        <p id="dani-2">Sub</p><b id="vrijeme-2">7:30 - 14:00</b>
      </div>
      <div className="clock__icon__container">
        <img src={clockIcon} alt="clockIcon" />
      </div>
    </div>
  );
}

const Lokacija: React.FC = () => {
  return (
    <div className="lokacija__component">
      <div className="info">
        <h1><span className="lokacija__dot" id="dot__1" />Lokacija</h1>
        <p><span className="lokacija__dot" id="dot__2" />Javorje, Savska ulica 14</p>
      </div>
      <div className="iframe__maps__container">
        <iframe title="Lokacija" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d785.3399688995489!2d15.765089837781359!3d45.86132129050393!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4765cf3f2a3de5a1%3A0x26ca5f675ba0cd34!2sSavska%20ul.%2014%2C%2010291%2C%20Javorje!5e0!3m2!1shr!2shr!4v1707752631118!5m2!1shr!2shr" width="100%" height="100%" style={{ border: 0 }} allowFullScreen={false} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
  );
}

export default ContactPage;

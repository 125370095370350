import React, { useEffect } from "react";
import { useResizeDetector } from "react-resize-detector";
import "./index.css";

interface BannerProps extends React.ComponentProps<"div">{
  title: string,
  subtitle: string
  dataCallback?: (data: { heightPx: number }) => void
}

const Banner: React.FC<BannerProps> = (props) => {
  const { title, subtitle, dataCallback, ...rest } = props;
  const { height, ref } = useResizeDetector();
  useEffect(() => {
    if (ref.current && dataCallback) {
      dataCallback({ heightPx: ref.current.offsetHeight });
    }
  }, [height, dataCallback, ref]);
  return (
    <div className="banner" {...rest} ref={ref}>
      <h1>{title}</h1>
      <h3>{subtitle}</h3>
    </div>
  );
}

export default Banner;

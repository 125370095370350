import React from "react";
import "./index.css";

import Hero from "../../components/Hero";

interface HomePageProps extends React.ComponentProps<"div"> {
};

const HomePage: React.FC<HomePageProps> = (props) => {
  const { ...rest } = props;
  return (
    <Hero { ...rest } ref={null}>
      <div className="home__content">
        <h1>Bruno Kovačić</h1>
        <h3>Obrt za autoprijevozništvo zemljane radove i cestogradnju.</h3>
      </div>
    </Hero>
  );
}

export default HomePage;

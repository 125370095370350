import React from "react";
import "./index.css";

import Hero from "../../components/Hero";
import blueprint from "../../images/blueprint.avif";
import { SecondaryButton } from "../../components/Button";

interface AboutPageProps extends React.ComponentProps<"div">{
};

const AboutPage: React.FC<AboutPageProps> = (props) => {
  const { ...rest } = props;
  return (
    <Hero { ...rest } ref={null}>
      <div className="blueprint__container">
        <img id="blueprint-1" src={blueprint} alt="blueprint-1" />
        <img id="blueprint-2" src={blueprint} alt="blueprint-2" />
      </div>
      <div className="about__content">
        <h1>O nama</h1>
        <article>
          <section className="about__subsection" id="kvaliteta__i__predanost">
            <h1>Kvaliteta i predanost</h1>
            <p>
              Od 1996. postavljamo nove standarde za kvalietu
              u ovome sektoru, te je upravo naša predanost
              kvalitetnom radu  temelj uspjeha tvrtke. Trajna
              i izdržljiva rješenja ističu Obrt Bruno Kovačić od ostalih.
            </p>
          </section>
          <section className="about__subsection" id="profesionalnost__i__iskustvo">
            <h1>Profesionalnost i iskustvo</h1>
            <p>
              S više od 30 godina iskustva Obrt Bruno Kovačić
              predstavlja sam vrh profesionalnosti i stručnosti.
              Naš tim jamči vrhunsku izvedbu u svakom projektu.
            </p>
          </section>
          <hr />
        </article>
        <SecondaryButton text="Saznaj više&nbsp;&nbsp;>>" />
      </div>
    </Hero>
  );
}

export default AboutPage;
